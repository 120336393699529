module.exports={
  "SITE_NAME": "DigJourney",
  "Loading": "Laddar",
  "the homepage": "startsidan",
  "There is no page at this address. Try finding your way using the menu or from %s.": "Se om du kan hitta det du sÃ¶ker i menyn eller frÃ¥n %s.",
  "try again": "prova igen",
  "You seem to be offline. Check your network connection.": "Du verkar inte ha nÃ¥gon uppkoppling. VÃ¤nligen kontrollera att du Ã¤r ansluten till internet.",
  "We apologize, an error has occured on our site.": "Nu verkar nÃ¥got ha gÃ¥tt galet.",
  "Toggle navigation": "Visa menyn",
  "Submit": "Skicka",
  "Try again": "Prova igen",
  "No page found": "Hoppsan",
  "Copyright": "Copyright",
  "Read more": "LÃ¤s mer",
  "website": "Hemsidan",
  "homepage": "Hemsidan",
  "post_listing": "Alla blogginlÃ¤gg",
  "post": "BlogginlÃ¤gg",
  "product_listing": "VÃ¥ra tjÃ¤nster",
  "product": "RaÌdgivning",
  "course_listing": "Alla utbildningar",
  "course": "Utbildning",
  "page": "Ãmne",
  "Web": "WebblÃ¤nk",
  "Media": "",
  "Â© %s DigJourney": "Â© %s DigJourney",
  "Fill in your e-mail address": "Fyll i din e-postadress",
  "Subscribe": "Prenumerera",
  "Hooray! You'll hear from us soon.": "Hurra! RÃ¤kna med att hÃ¶ra frÃ¥n oss snart.",
  "Sorry, this page does not exist.": "Den hÃ¤r sidan finns inte.",
  "Apply": "AnsÃ¶k",
  "Go to application": "Till anmÃ¤lan",
  "Location": "Plats",
  "Teachers": "Kursledare",
  "Upcoming course dates": "Kommande kurstillfÃ¤llen",
  "The course includes": "Detta ger dig",
  "Kursledare": "Kursledare",
  "Read more about the course": "LÃ¤s mer om utbildningen",
  "Target audience": "MÃ¥lgrupp",
  "Duration": "LÃ¤ngd",
  "Interested?": "LÃ¥ter det spÃ¤nnande?",
  "Published on %s": "Publicerat den %s",
  "Published by %s on %s": "Skrivet av %s den %s",
  "Show more": "Visa fler insikter",
  "Related posts": "Fler insikter",
  "Play %s": "Spela %s",
  "Rating: %s of 5": "Betyg: %s av 5",
  "Available at": "Finns hos",
  "Written by %s": "Skriven av %s",
  "Oops": "Inget hÃ¤r",
  "Member Exists": "Den e-postadressen finns redan"
}
